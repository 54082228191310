import styled, { css, keyframes } from "styled-components";

import deskImage from "./images/desk.gif";
import floorImage from "./images/floor.png";
import robotImage from "./images/robot.gif";
import robotImageMobile from "./images/robot-mobile.gif";

const animations = {
  fadeIn: keyframes`
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    `,
  fadeInUp: keyframes`
        from{
            transform: translateY(5%);
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    `,
  fallDown: keyframes`
        0% { transform: rotateZ(0deg); }
        20% { transform: rotateZ(10deg); animation-timing-function: ease-out; }
        40% { transform: rotateZ(17deg); }
        60% { transform: rotateZ(16deg); }
        100% { transform: translateY(100%) rotateZ(17deg); }
    `,
};

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.red};
  background-image: url(${floorImage});
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 1px 100px;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;

  ${({ animation }) =>
    animation &&
    css`
      animation-name: ${animations[animation]};
      animation-duration: 1000ms;
      animation-timing-function: ease-in;
      animation-fill-mode: both;
      transform-origin: 0% 0%;
    `}

  @media (max-width: ${({ theme }) => theme.breaks.lg}) {
    background-size: 1px 70px;
  }
`;

export const Robot = styled.div`
  animation: ${animations.fadeIn} forwards ease-in 600ms 200ms;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 1200px;

  &:after {
    background-image: url(${robotImage});
    background-size: cover;
    content: "";
    display: block;
    padding-top: 48%;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breaks.lg}) {
      background-image: url(${robotImageMobile});
    }
  }

  @media (max-width: ${({ theme }) => theme.breaks.lg}) {
    width: 900px;
  }
`;

export const Desk = styled.div`
  animation: ${animations.fadeIn} forwards ease-in 500ms 400ms;
  bottom: -16px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 540px;

  &:after {
    background-image: url(${deskImage});
    background-size: cover;
    content: "";
    display: block;
    padding-top: 45%;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breaks.lg}) {
    width: 400px;
  }
`;

export const Content = styled.div`
  align-items: center;
  animation: ${animations.fadeInUp} forwards ease 600ms 600ms;
  display: flex;
  flex-direction: column;
  max-width: 64ch;
  opacity: 0;
  padding: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: 128px;

  @media (max-width: ${({ theme }) => theme.breaks.sm}) {
    max-width: 40ch;
  }
`;
