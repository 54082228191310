import React, { Fragment } from 'react';
import HomePageHero from '../components/home-page-hero';

function HomePage() {
  return (
    <Fragment>
      <HomePageHero />
    </Fragment>
  );
}

export default HomePage;
