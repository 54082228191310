import React, { memo } from "react";
import { useSpring, animated } from "react-spring";
import Button from "../button";
import Icon from "../icon";
import TransitionLink from "../transition-link";
import Text from "../text";
import * as Styles from "./styles";

function HomePageHero() {
  const roomTranslate = (x) => `translate3d(${x / 20}px, 0, 0)`;
  const robotTranslate = (x) => `translate3d(${x / 40}px, 0, 0)`;

  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];

  const handleMouseMove = ({ clientX: x, clientY: y }) =>
    set({ xy: calc(x, y) });

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <Styles.Wrapper onMouseMove={handleMouseMove}>
      <Styles.Content>
        <Text variant="h1" marginBottom={3} align="center">
          Hello, I'm Steve
        </Text>

        <Text variant="lead" marginBottom={5} align="center">
          A Senior Frontend Developer with over 10 years experience developing
          websites, applications and games with HTML, CSS, JS and React.
        </Text>

        <Button as={TransitionLink} to="/technologies/" fall red>
          Technologies <Icon icon="arrowRight" marginLeft={1} />
        </Button>
      </Styles.Content>

      <Styles.Robot
        as={animated.div}
        style={{ transform: props.xy.interpolate(robotTranslate) }}
      />

      <Styles.Desk
        as={animated.div}
        style={{ transform: props.xy.interpolate(roomTranslate) }}
      />
    </Styles.Wrapper>
  );
}

export default memo(HomePageHero);
